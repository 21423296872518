import React, {useState} from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';

const Result = () => {
    return (
        <p className="success-message">Thank you. We will contact with you soon.</p>
    )
}

const NewsLettterOne = () => {
    
    const [submit, setSubmit] = useState( 'Submit Now' );
    const [result, setResult] = useState( false );
    const [name, setName] = useState( '' );
    const [email, setEmail] = useState( '' );
    const [website, setWebsite] = useState( '' );
    const [phone, setPhone] = useState( '' );

    const sendEmail = ( e ) => {
        e.preventDefault();


        setSubmit('Submitting...');

        
        let sendData = {
            name : name,
            email : email,
            website : website,
            phone : phone
        };
        /** use for test https://cors-anywhere.herokuapp.com/http://valoirinc.com/data-center/wp-admin/admin-ajax.php?action=book_appointment */ 
        fetch('https://valoirinc.com/data-center/wp-admin/admin-ajax.php?action=book_appointment', {
      method: "POST",
      body: JSON.stringify(sendData),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(
    (response) => (response.json())
      ).then((response)=> {
    if (response.status==='1') {
        setName('');
        setEmail('');
        setWebsite('');
        setPhone('');
        setResult( true );
        setSubmit('Submitted');
    } else{
      alert("Message failed to send." + response.errmsg);
    }
  });

  
        
    };

    setTimeout( () => {
        setResult( false );
    }, 5000);

    return (
        <div id="make-appointment" className="edu-newsletter-area newsletter-style-1 edu-section-gap bg-color-primary">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                <div className="col-lg-6 pr--40 pr_md--0 pr_sm--10">
                    
                    <form action="#" className="rts-appoinment-form" onSubmit={ sendEmail }>
                        <div className="inner pb--60">
                            
                            <div className="section-title text-start"><span className="pre-title">Get in Touch</span><h3 className="title">Make an Appointment</h3></div>
                            <div className="form-group- formresult">{ result ? <Result />  : null }</div>    
                            <input type="text" name="name" placeholder="Your Name" value={name}
          onChange={(e) => setName(e.target.value)} required/>
                            <input type="email" name="email" placeholder="Enter Email" value={email}
          onChange={(e) => setEmail(e.target.value)} required/>
                            <input type="text" name="website" placeholder="Wesite Url" value={website}
          onChange={(e) => setWebsite(e.target.value)} required/>
                            <input type="text" name="phone" placeholder="Phone Number" value={phone}
          onChange={(e) => setPhone(e.target.value)} required/>
                            <button className="rn-btn edu-btn">{submit} <i className="icon-arrow-right-line-right"></i></button>
                            
                        </div>
                    </form>
                    
                </div>
                <div className="col-lg-6 pl--20 mt_md--30 mt_sm--30">
                    <div className="appoinment-right">
                        <div className="title-area text-start section-title">
                            <span  className="pre-title">Completed stories!</span>
                            <h3 className="title" data-sal-delay="250" data-sal="slide-up" data-sal-duration="800">We do awesome contribute for our clients. Check some of our counter.</h3>
                        </div>
                        <div className="counter-wrapper-appoinment">
                            <div className="counter-single sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <h2 className="title"><span className="counter plus animated fadeInDownBig">140</span></h2>
                                <p className="disc">Countries Targeted</p>
                            </div>
                            <div className="counter-single sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <h2 className="title"><span className="counter plus animated fadeInDownBig">560</span></h2>
                                <p className="disc">Our
Happy clients</p>
                            </div>
                            <div className="counter-single sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <h2 className="title"><span className="counter plus animated fadeInDownBig">120</span></h2>
                                <p className="disc">Win Awards</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
            </div>
        </div>
    )
}

export default NewsLettterOne;