import React from 'react';
import { useParams } from 'react-router-dom';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne'
import FooterOne from '../../common/footer/FooterOne'
import HeaderTwo from '../../common/header/HeaderTwo'
import SEO from '../../common/SEO'

const ServiceDetails = ({services}) => {

    const { slug } = useParams();

    const dataItem = services.filter(blog => blog.slug === slug);
    
    const data = dataItem[0];

    const Title = data.title;

  return (
    <>
            <SEO title={`${Title} Service`} />
            <HeaderTwo styles="header-transparent- header-style-2" />
                <BreadcrumbOne
                    title={Title}
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl={`${Title} Service`}
                />
                <div className="edu-elements-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                            <div  className="service description"
      dangerouslySetInnerHTML={{__html: data.content}}
    />
                            </div>
                        </div>
                    </div>
                </div>
                <FooterOne />
        </>
  )
}

export default ServiceDetails
